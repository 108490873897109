<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">{{ title }}</h4>

          <div class="page-title-right">
            <!--  <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
            <div>
              <b-button id="show-btn" variant="primary" :to="{ name: 'roulettes-index' }">{{ $t('helpers.listOf') }}
                {{ $t('sidebar.games.subItem.roulettes') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 justify-content-center">
      <div class="col-xl-8">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <!-- <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Remove</a>
              </b-dropdown> -->
            </div>
            <hr class="my-4" />
            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <p class="mb-1">{{ $t('roulettes.name') }}:</p>
                  <h5 class="font-size-16">{{ item.name }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">
                    {{ $t('roulettes.code') }}:
                  </p>
                  <h5 class="font-size-16">{{ item.code }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('profile.creationDate') }}:</p>
                  <h5 class="font-size-16">{{ item.createdAt }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('helpers.updatedAt') }}:</p>
                  <h5 class="font-size-16">{{ item.updatedAt }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('roulettes.logo') }}:</p>
                  <h5 class="font-size-16">{{ item.logo }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('tableReports.status') }}:</p>
                  <h5 class="font-size-16">{{ item.status }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('roulettes.form.roundDuration') }}:</p>
                  <h5 class="font-size-16">{{ item.roundDuration }}</h5>
                </div>
                <!-- <div class="mt-4 mb-1">
                  <p class="mb-1">Indica hace cuántas rondas se realizó:</p>
                  <h5 class="font-size-16">{{ item.lastJackpot }}</h5>
                </div> -->
                <!-- <div class="mt-4 mb-1">
                  <p class="mb-1">
                    Indica cuántas rondas se requieren para realizar un jackpot:
                  </p>
                  <h5 class="font-size-16">{{ item.jackpotRounds }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">Ronda actual:</p>
                  <h5 class="font-size-16">{{ item.currenJackpotRound }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">Valor de pago de jackpots:</p>
                  <h5 class="font-size-16">{{ item.jackpotWin }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">Indicador de la interfaz:</p>
                  <h5 class="font-size-16">{{ item.interface }}</h5>
                </div> -->
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('sidebar.currency') }}:</p>
                  <div v-for="(currency, index) in currencies" :key="index">
                    <h5 class="font-size-16">{{ currency.name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";

/**
 * Profile component
 */
export default {
  components: { Layout },
  page: {
    title: "Ruleta",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Ruleta",
      item: [],
      currencies: [],
    };
  },
  created() {
    if (this.$router.currentRoute.params.id) {
      this.$http
        .get(`/roulettes/${this.$router.currentRoute.params.id}`)
        .then(({ data }) => {
          this.item = data.roulette[0];

          this.currencies = data.roulette[0].currencies;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.$router.push({ path: "/roulettes" });
    }
  },
};
</script>
